import axios from "axios";
import { toast } from "react-toastify";
import { Navigate } from 'react-router-dom';
import { Cryptography } from "../../cryptography/Cryptography";

// const host = "http://192.168.0.199:8005";
// const host = "http://192.168.0.123:8005";

// const node = "http://192.168.0.116:8080";
const node = "https://procnode.asmdairy.in";
// const node = "http://172.20.10.3:9000";


const host = "https://test.trofeosolution.com/public"

const sound = require("../../../../src/assets/mp3/wrong-tone.mp3")

const audio = new Audio(sound);

const handleTokenExpiration = () => {
    // Remove the token from localStorage
    localStorage.removeItem("token");
    localStorage.clear()
    audio.play();
    // Use the provided `navigate` function to redirect to the login page
    Navigate("/auth-login", { replace: true });
};

const speech = (message) => {
    // Voice-over for the error message using Web Speech API
    if ('speechSynthesis' in window) {
        const utterance = new SpeechSynthesisUtterance(message);
        speechSynthesis.speak(utterance);
    }
}

const notifyError = (error) => {
    if (error.response && error.response.status === 401) {
        handleTokenExpiration();
    } else {
        const message = error.response ? error.response.data.error : "Network error";
        toast.error(message);
        audio.play(); // Play the audio when an error toast is displayed
    }
};

// Add a request interceptor to check the token before each request
axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor to handle 401 status
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Token expired or unauthorized, handle accordingly
            const message = error.response.data;
            toast.error(message.error);
            // speech(message.error)
            handleTokenExpiration();
        }
        return Promise.reject(error);
    }
);

export const postFormdata = async (url, data) => {
    const token = localStorage.getItem('token')

    try {
        const response = await axios
            .post(host + url, Cryptography.encrypt(data), {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                },
            });
        return response.data;
    } catch (error) {

        return error;
    }
};
export const post = async (url, data) => {
    const token = localStorage.getItem('token')

    try {
        const response = await axios
            .post(host + url, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
        toast.success(response.data.message)
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token expired during login, handle accordingly
            handleTokenExpiration();
        } else {
            const message = error.response.data;
            toast.error(message.error);
            // speech(message.error)
            audio.play();
        }
        return error;
    }
};

export const postNode = async (url, data) => {
    const token = localStorage.getItem('token')

    try {
        const response = await axios
            .post(node + url, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
        toast.success(response.data.message)
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token expired during login, handle accordingly
            handleTokenExpiration();
        } else {
            const message = error.response.data;
            toast.error(message.error);
            // speech(message.error)
            audio.play();
        }
        return error;
    }
};
export const postToast = async (url, data) => {
    const token = localStorage.getItem('token')

    try {
        const response = await axios
            .post(host + url, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token expired during login, handle accordingly
            handleTokenExpiration();
        } else {
            const message = error.response.data;
            toast.error(message.error);
            audio.play();
            // speech(message.error)
        }
        return error;
    }
};


export const postToastNode = async (url, data) => {
    const token = localStorage.getItem('token')

    try {
        const response = await axios
            .post(node + url, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token expired during login, handle accordingly
            handleTokenExpiration();
        } else {
            const message = error.response.data;
            toast.error(message.error);
            audio.play();
            // speech(message.error)
        }
        return error;
    }
};

export const loginPost = async (url, data) => {
    try {
        const response = await axios
            .post(host + url, data, {
                headers: {
                    "Content-Type": "application/json",

                },
            });
        toast.success(response.data.message)
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token expired during login, handle accordingly
            handleTokenExpiration();
        } else {
            const message = error.response.data;
            toast.error(message.error);
            audio.play();
            // speech(message.error)
        }
        return error;
    }
};

export const get = async (url) => {
    const token = localStorage.getItem('token')
    try {
        const response = await axios
            .get(host + url, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token expired during login, handle accordingly
            handleTokenExpiration();
        } else {
            const message = error.response.data;
            toast.error(message.error);
            audio.play();
            // speech(message.error)

        }
        return error;
    }
};
export const put = async (url, data) => {
    const token = localStorage.getItem('token')

    try {
        const response = await axios
            .put(host + url, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
        toast.success(response.data.message)
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token expired during login, handle accordingly
            handleTokenExpiration();
        } else {
            const message = error.response.data;
            toast.error(message.error);
            audio.play();
            // speech(message.error)

        }
        return error;
    }
};

export const getNode = async (url) => {
    const token = localStorage.getItem('token')
    try {
        const response = await axios
            .get(node + url, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token expired during login, handle accordingly
            handleTokenExpiration();
        } else {
            const message = error.response.data;
            toast.error(message.error);
            audio.play();
            // speech(message.error)

        }
        return error;
    }
};