import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Carousel } from 'react-bootstrap';
import { Icon, Media } from '../../../components';

const SynchronizedCarousels = ({ directFarmer, carouselInterval }) => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % 3); // Assuming 3 items in each carousel
        }, carouselInterval);

        return () => clearInterval(timer);
    }, [carouselInterval]);

    const carouselProps = {
        controls: false,
        indicators: false,
        interval: null,
        activeIndex: index,
        onSelect: () => { }, // Empty function to prevent manual selection
    };

    const renderCarouselItem = (data, symbol, percentage, i) => (
        <Carousel.Item key={`carousel-item-${i}`}>
            <div className="amount h1">{data}</div>
            <div className="d-flex align-items-center smaller">
                <div className={symbol === "up" ? "change up" : "change down"}>
                    <Icon name={symbol === "up" ? "upword-alt-fill" : "downward-alt-fill"} />
                    <span className="ms-1">{percentage}%</span>
                </div>
            </div>
        </Carousel.Item>
    );

    const renderCard = (title, data, symbol, percentage) => (
        <Col sm="2" key={`card-${title}`}>
            <Card className="h-100 mb-2">
                <Card.Body>
                    <div className="card-title-group align-items-start">
                        <div className="card-title">
                            <h4 className="title">{title}</h4>
                        </div>
                        <Media size="sm" shape="circle" variant="success-soft">
                            <Icon name="bar-chart-fill" />
                        </Media>
                    </div>
                    <Carousel {...carouselProps}>
                        {[...Array(3)].map((_, i) => renderCarouselItem(data, symbol, percentage, i))}
                    </Carousel>
                </Card.Body>
            </Card>
        </Col>
    );

    return (
        <Col lg="12" className='mb-2'>
            <Row className="g-gs">
                {renderCard("Avg.Fat", directFarmer?.avgDirectFarmer?.avg_tot_fat, directFarmer?.avgDirectFarmer?.tot_fat_symbol, directFarmer?.avgDirectFarmer?.tot_fat_percentage)}
                {renderCard("Avg.Snf", directFarmer?.avgDirectFarmer?.avg_tot_snf, directFarmer?.avgDirectFarmer?.tot_snf_symbol, directFarmer?.avgDirectFarmer?.tot_snf_percentage)}
                {renderCard("Avg.Ltr", directFarmer?.avgDirectFarmer?.avg_tot_qty_litre, directFarmer?.avgDirectFarmer?.avg_tot_qty_litre_symbol, directFarmer?.avgDirectFarmer?.tot_qty_litre_percentage)}
                {renderCard("Avg.Kg", directFarmer?.avgDirectFarmer?.avg_tot_qty_kg, directFarmer?.avgDirectFarmer?.tot_qty_kg_symbol, directFarmer?.avgDirectFarmer?.tot_qty_kg_percentage)}
                {renderCard("Tot.Ltr", directFarmer?.avgDirectFarmer?.tot_qty_litre, directFarmer?.avgDirectFarmer?.tot_qty_kg_symbol, directFarmer?.avgDirectFarmer?.tot_qty_litre_percentage)}
                {renderCard("Tot.Kg", directFarmer?.avgDirectFarmer?.tot_qty_kg, directFarmer?.avgDirectFarmer?.tot_qty_kg_symbol, directFarmer?.avgDirectFarmer?.tot_qty_kg_percentage)}
            </Row>
        </Col>
    );
};

export default SynchronizedCarousels;
