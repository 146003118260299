import { useState, useEffect, useCallback } from 'react';
import { io } from 'socket.io-client';

const SOCKET_URL = 'https://procapi.asmdairy.in/'; // Base URL without socket.io path

const useSocketConnection = (initialMessage) => {
    const [socket, setSocket] = useState(null);
    const [directFarmer, setDirectFarmer] = useState([]);
    const [vlccFarmer, setVlccFarmer] = useState([]);
    const [days, setDays] = useState('');
    const [isConnected, setIsConnected] = useState(false);
    const [error, setError] = useState(null);
    const [reconnectAttempts, setReconnectAttempts] = useState(0);

    // Initialize socket connection
    const initializeSocket = useCallback(() => {
        if (socket) {
            console.log('Socket already exists, cleaning up...');
            socket.close();
            socket.removeAllListeners();
        }

        console.log('Initializing socket connection...');

        const socketInstance = io(SOCKET_URL, {
            transports: ['websocket'],
            reconnection: true,
            reconnectionAttempts: 3,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            timeout: 20000,
            autoConnect: false, // Prevent auto-connection
            path: '/socket.io/', // Explicit path
        });

        // Setup event handlers before connecting
        socketInstance.on('connect', () => {
            console.log('Socket connected successfully');
            setIsConnected(true);
            setError(null);
            setReconnectAttempts(0);

            // Send initial message only after successful connection
            if (initialMessage) {
                console.log('Sending initial message:', initialMessage);
                socketInstance.emit('message', JSON.stringify(initialMessage));
            }
        });

        socketInstance.on('disconnect', (reason) => {
            console.log('Socket disconnected:', reason);
            setIsConnected(false);

            if (reason === 'io server disconnect') {
                // Disconnected by server, don't attempt to reconnect
                setError('Disconnected by server');
            } else if (reconnectAttempts >= 3) {
                setError('Maximum reconnection attempts reached');
                socketInstance.disconnect();
            }
        });

        socketInstance.on('connect_error', (err) => {
            console.error('Connection error:', err.message);
            setError(`Connection error: ${err.message}`);
            setIsConnected(false);
            setReconnectAttempts(prev => prev + 1);

            if (reconnectAttempts >= 3) {
                console.log('Max reconnection attempts reached, stopping...');
                socketInstance.disconnect();
            }
        });

        socketInstance.on('message', (data) => {
            try {
                console.log('Raw message received:', data);
                const details = typeof data === 'string' ? JSON.parse(data) : data;
                console.log('Parsed message:', details);

                if (details?.data) {
                    setDirectFarmer(details.data.direct_farmer || []);
                    setVlccFarmer(details.data.vlcc_farmer || []);
                }
            } catch (error) {
                console.error('Error parsing message:', error);
                setError(`Error parsing message: ${error.message}`);
            }
        });

        // Start connection attempt
        console.log('Attempting to connect...');
        socketInstance.connect();

        setSocket(socketInstance);
        return socketInstance;
    }, []);

    // Setup socket connection
    useEffect(() => {
        const socketInstance = initializeSocket();

        // Cleanup function
        return () => {
            console.log('Cleaning up socket connection...');
            if (socketInstance) {
                socketInstance.removeAllListeners();
            }
        };
    }, [initializeSocket]);

    // Days function to send updates
    const DaysFunc = useCallback((value) => {
        setDays(value);
        if (socket && isConnected) {
            try {
                const message = JSON.stringify({ days: value });
                console.log('Sending days update:', message);
                socket.emit('message', message);
            } catch (error) {
                console.error('Error sending days update:', error);
                setError(`Error sending update: ${error.message}`);
            }
        } else {
            console.warn('Cannot send update - socket not connected');
        }
    }, [socket, isConnected]);

    return {
        directFarmer,
        vlccFarmer,
        days,
        DaysFunc,
        isConnected,
        error,
        reconnectAttempts
    };
};

export default useSocketConnection;