import { Row, Col, Card, Dropdown, Tab, Nav, Table, Button, Carousel } from 'react-bootstrap';
import Layout from '../layout/default';
import { Media, Icon, ChartLabel, ChartLegend, CustomDropdownMenu, CustomDropdownToggle, Pureknob, } from '../components';
import { ChartLine } from "../components/Chart/Charts";
import { Colors } from '../utilities/index';
import hexRGB from '../utilities/hexRGB';
import { useEffect, useState } from 'react';
import { findStartMidEnd } from './masters/shared/Date';
import useWebSocketConnection from './masters/api/Socket';
import SocketFilter from './masters/shared/Filter/SocketFilter';
import { postToastNode } from './masters/api/Api';
import DataTable from 'react-data-table-component';
import AttendenceFilter from './masters/shared/Filter/AttendenceFilter';
import SessionStartSelector from './masters/shared/session/SessionStart';
import SynchronizedCarousels from './masters/shared/SynchronizedCarousels';


function Home() {
  const initialMessage = {
    days: "today", session: "M"
  }
  const [topFarmerData, setTopFarmerData] = useState([])
  const [attendence, setAttendence] = useState(null)
  const [belowFarmerData, setBelowFarmerData] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [showAFilter, setShowAFilter] = useState(false)
  const [selectedValue, setSelectedValue] = useState(null)
  const [directChartData, setDirectChartData] = useState([])
  const [vlccChartData, setVlccChartData] = useState([])
  const { directFarmer, vlccFarmer, days } = useWebSocketConnection(initialMessage);
  const [showDays, setShowDays] = useState("This Week")
  const [sessionList, setSessionList] = useState("M")


  const attendenceDate = (value) => {
    const data = {
      date: value.date,
      session: value.session
    }
    fetchAttendence(data)
  }

  const fetchAttendence = async (payload) => {
    const url = `/api/farmerDayStatus`;

    try {
      const response = await postToastNode(url, payload);
      if (response.status) {
        console.log("response", response.data.farmer);
        const data = response.data.farmer
        setAttendence(data)
        // setLoading(false)
      } else {
        // setLoading(false)
      }
    } catch (error) {
      console.error("Error while posting data:", error);
    }
  }


  const fetchTopFarmer = async (payload) => {
    const url = `/api/minAndMaxOfFarmer`;

    try {
      const response = await postToastNode(url, payload);
      if (response.status) {
        console.log("response", response.data);
        const data = response.data.farmer
        setTopFarmerData(data.maxOfLitreFarmer)
        setBelowFarmerData(data.minOfLitreFarmer)
        // setLoading(false)
      } else {
        // setLoading(false)
      }
    } catch (error) {
      console.error("Error while posting data:", error);
    }
  }


  const handleFilter = (value) => {
    console.log("value===>", value);
    const initialMessage = {
      days: value.days,
      session: value.session
    }
    const show = filterDayFunc(value.days)
    // set Days in days State
    setShowDays(show)
    fetchChartreport(initialMessage)

  }

  const fetchChartreport = async (payload) => {
    const url = `/api/overallreport`;
    try {
      const response = await postToastNode(url, payload);
      console.log("response", response);
      if (response.status) {
        console.log("response", response.data);
        const data = response.data
        setDirectChartData(data.direct_farmer)
        setVlccChartData(data.vlcc_farmer)
        // setLoading(false)
      } else {
        // setLoading(false)
      }
    } catch (error) {
      console.error("Error while posting data:", error);
    }
  }

  useEffect(() => {
    const payload = {
      date: "",
      session: "M"
    }

    fetchAttendence(payload)
    const initialMessage = {
      days: "this_week",
      session: ""
    }
    fetchChartreport(initialMessage)
  }, []);


  useEffect(() => {
    const payload = {
      count: 5,
      date: "",
      session: "M"
    }
    fetchTopFarmer(payload)
  }, []);

  const handleCodeDetails = (id) => {
    const url = `/profile-page/${id}/direct`;
    window.open(url, '_blank');
  }


  const sessionHandler = (session) => {
    setSessionList(session)
    const payload = {
      count: 5,
      date: "",
      session: session
    }
    fetchTopFarmer(payload)
  }

  const topFarmerColumn = [
    {
      name: "FARMER CODE",
      selector: (row) => (
        <span
          style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
          onClick={() => {
            handleCodeDetails(row.direct_farmer_id)
          }}
        >
          {row.code}
        </span>

      ),
      center: true,
      width: '33%',
      sortable: true
    },
    {
      name: "FARMER NAME",
      selector: row => row.name,
      center: true,
      width: '33%',
      sortable: true
    },
    {
      name: "TOTAL LITRES",
      selector: row => row.tot_qty_litre,
      center: true,
      width: '33%',
      sortable: true
    }
  ]

  const worstFarmerColumn = [
    {
      name: "FARMER CODE",
      selector: (row) => (
        <span
          style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
          onClick={() => {
            handleCodeDetails(row.direct_farmer_id)
          }}
        >
          {row.code}
        </span>
      ),
      center: true,
      width: '33%',
      sortable: true
    },
    {
      name: "FARMER NAME",
      selector: row => row.name,
      center: true,
      width: '33%',
      sortable: true
    },
    {
      name: "TOTAL LITRES",
      selector: row => row.tot_qty_litre,
      center: true,
      width: '33%',
      sortable: true
    }
  ]

  // const chartLabels = findStartMidEnd(directChartData?.dates)?.map(date => (
  //   <ChartLabel className="chart-label-small" key={date}>
  //     {date}
  //   </ChartLabel>
  // ));

  const chartLabels = () => (
    <ChartLabel className="chart-label-small" >
      2
    </ChartLabel>)


  // const chartLabelsVlcc = findStartMidEnd(vlccFarmer?.dates)?.map(date => (
  //   <ChartLabel className="chart-label-small" key={date}>
  //     {date}
  //   </ChartLabel>
  // ));

  const chartLabelsVlcc = () => (
    <ChartLabel className="chart-label-small">
      2
    </ChartLabel>
  )

  let totalAttendenceAbsent = {
    size: attendence?.total_farmer ? attendence?.total_farmer : "",
    value: attendence?.absentFarmer,
    angleOffset: 0.4,
    angleStart: 1,
    angleEnd: 1,
    colorFg: Colors.primary,
    trackWidth: "0.15"
  };

  let totalClientsChart = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    yAxis: false,
    xAxis: false,
    maxTicksLimit: 4,
    datasets: [
      {
        tension: .4,
        label: "Total Clients",
        borderColor: Colors.success,
        backgroundColor: hexRGB(Colors.success, 0.2),
        borderWidth: 4,
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointHoverBackgroundColor: Colors.success,
        borderCapStyle: "round",
        fill: true,
        data: [38, 40, 23, 80, 70, 110]
      }
    ]
  };

  // session Fat avg chart
  let fat_avg_data = {
    labels: directChartData?.avg?.map(item => item.label),
    yAxis: true,
    xAxis: false,

    maxTicksLimit: 5,
    datasets: [
      {
        tension: .4,
        label: "Avg.Fat",
        borderWidth: 2,
        borderColor: Colors.primary,
        backgroundColor: hexRGB(Colors.primary, 0.2),
        pointBackgroundColor: Colors.primary,
        pointBorderColor: Colors.white,
        fill: true,
        data: directChartData?.avg?.map(item => item.avg_tot_fat)
      }
    ],


  };

  // session Snf avg chart
  let snf_avg_data = {
    labels: directChartData?.avg?.map(item => item.label),
    yAxis: true,
    xAxis: false,
    maxTicksLimit: 8,
    datasets: [
      {
        tension: .4,
        label: "Avg.Snf",
        borderColor: Colors.success,
        borderWidth: 2,
        backgroundColor: hexRGB(Colors.success, 0.2),
        pointBackgroundColor: Colors.success,
        pointBorderColor: Colors.white,
        fill: true,
        data: directChartData?.avg?.map(item => item.avg_tot_snf)
      }
    ]
  };


  // session Snf avg chart
  let lit_avg_data = {
    labels: directChartData?.avg?.map(item => item.label),
    yAxis: true,
    xAxis: false,
    maxTicksLimit: 8,
    datasets: [
      {
        tension: .4,
        label: "Avg.Lit",
        borderColor: Colors.mustedYellow,
        borderWidth: 2,
        backgroundColor: hexRGB(Colors.mustedYellow, 0.2),
        pointBackgroundColor: Colors.mustedYellow,
        pointBorderColor: Colors.white,
        pointHoverBackgroundColor: Colors.mustedYellow,
        fill: true,
        data: directChartData?.avg?.map(item => item.avg_tot_qty_litre)
      }
    ]
  };

  // session Snf avg chart
  let qty_avg_data = {
    labels: directChartData?.avg?.map(item => item.label),
    yAxis: true,
    xAxis: false,
    maxTicksLimit: 8,
    datasets: [
      {
        tension: .4,
        label: "Avg.KG",
        borderColor: Colors.hotPink,
        borderWidth: 2,
        backgroundColor: hexRGB(Colors.hotPink, 0.2),
        pointBorderColor: Colors.white,
        pointBackgroundColor: Colors.hotPink,
        pointHoverBackgroundColor: Colors.white,
        fill: true,
        data: directChartData?.avg?.map(item => item.avg_tot_qty_kg)
      }
    ]
  };

  // session Fat avg chart
  let fat_avg_data_vlcc = {
    labels: vlccChartData?.avg?.map(item => item.label),
    yAxis: false,
    xAxis: false,
    maxTicksLimit: 8,
    datasets: [
      {
        tension: .4,
        label: "Avg.Sessions",
        borderColor: Colors.success,
        borderWidth: 2,
        backgroundColor: hexRGB(Colors.success, 0.2),
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointHoverBackgroundColor: Colors.success,
        fill: true,
        data: vlccChartData?.avg?.map(item => item.avg_tot_fat)
      }
    ]
  };

  // session Snf avg chart
  let snf_avg_data_vlcc = {
    labels: vlccChartData?.avg?.map(item => item.label),
    yAxis: false,
    xAxis: false,
    datasets: [
      {
        tension: .4,
        label: "Avg.Sessions",
        borderColor: Colors.purple,
        borderWidth: 2,
        backgroundColor: hexRGB(Colors.purple, 0.2),
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointHoverBackgroundColor: Colors.purple,
        fill: true,
        data: vlccChartData?.avg?.map(item => item.avg_tot_snf)
      }
    ]
  };

  // session Snf avg chart
  let lit_avg_data_vlcc = {
    labels: vlccChartData?.avg?.map(item => item.label),
    yAxis: false,
    xAxis: false,
    datasets: [
      {
        tension: .4,
        label: "Avg.Sessions",
        borderColor: Colors.mustedYellow,
        borderWidth: 2,
        backgroundColor: hexRGB(Colors.mustedYellow, 0.2),
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointHoverBackgroundColor: Colors.mustedYellow,
        fill: true,
        data: vlccChartData?.avg?.map(item => item.avg_tot_qty_litre)
      }
    ]
  };

  // session Snf avg chart
  let qty_avg_data_vlcc = {
    labels: vlccChartData?.avg?.map(item => item.label),
    yAxis: false,
    xAxis: false,
    datasets: [
      {
        tension: .4,
        label: "Avg.Sessions",
        borderColor: Colors.hotPink,
        borderWidth: 2,
        backgroundColor: hexRGB(Colors.hotPink, 0.2),
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointHoverBackgroundColor: Colors.hotPink,
        fill: true,
        data: vlccChartData?.avg?.map(item => item.avg_tot_qty_kg)
      }
    ]
  };



  // audience overview chart
  let attendenceVLCC = {
    labels: vlccFarmer?.dates?.map(date => date),
    borderDash: [8, 4],
    datasets: [
      {
        tension: .4,
        borderWidth: 2,
        borderColor: Colors.warning,
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointHoverBackgroundColor: Colors.warning,
        label: "Absent Farmers",
        data: vlccFarmer?.avg?.map(item => item?.absent_farmer)
      },
      {
        tension: .4,
        borderWidth: 2,
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        borderColor: Colors.info,
        pointHoverBackgroundColor: Colors.info,
        label: "Present Farmers",
        data: vlccFarmer?.avg?.map(item => item?.present_farmer)
      }
    ]
  };


  const [activeTab, setActiveTab] = useState("direct");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const handleDropdownChange = (eventKey) => {
    setSelectedValue(eventKey);
    const payload = {
      count: eventKey,
      date: "2024-02-03",
      session: "M"
    }
    fetchTopFarmer(payload)
    console.log("eventKey", eventKey);
  };



  const filterDayFunc = (value) => {
    if (value === "this_week") {
      return "This Week";
    } else if (value === "last_week") {
      return "Last Week";
    } else if (value === "this_month") {
      return "This Month";
    } else if (value === "last_month") {
      return "Last Month";
    } else {
      return ""
    }
  }
  const currentDate = new Date().toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });


  return (
    <Layout title="Analytics">

      <Tab.Container id="pills-tabs-example" activeKey={activeTab}
        onSelect={handleTabChange}>
        <Nav variant="pills" className="mb-3">

          <Nav.Item>
            <Nav.Link eventKey="direct">Direct Farmer</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="vlcc">VLCC Farmer</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Row className="mb-3">
            <Col>
              <h4>{currentDate}</h4>
            </Col>
          </Row>
          <SynchronizedCarousels
            directFarmer={directFarmer}
            carouselInterval={2000} // Adjust this value as needed
          />

          <Tab.Pane eventKey="direct">
            <Card>
              <Card.Body>
                <Col sm="12" className='mt-2' >
                  <div className='d-flex'>
                    <div style={{ marginBottom: "10px", backgroundColor: '#ff9966', fontWeight: "bold", borderRadius: '5px', textAlign: "center", alignItems: 'center', justifyContent: 'center', width: "100px" }}>
                      {showDays}
                    </div>

                    <div className='mx-3'>
                      <Button
                        style={{ height: "25px", width: "20px" }}
                        variant="primary"
                        onClick={() => setShowFilter(!showFilter)}
                      >
                        <Icon name='filter' />
                      </Button>
                    </div>

                  </div>
                </Col>
                {showFilter && <SocketFilter handleFilter={handleFilter} />}

                <Row className="g-gs">
                  <Col sm="6" xxl="3">
                    <Card className="h-100">
                      <Card.Body>
                        <div className="card-title-group align-items-start">
                          <div className="card-title">
                            <h4 className="title">Avg.Fat</h4>
                          </div>
                          <Media size="sm" shape="circle" variant="success-soft">
                            <Icon name="bar-chart-fill"></Icon>
                          </Media>
                        </div>
                        <div className="mt-2 mb-4">
                          <div className="amount h1">{directChartData?.avgDirectFarmer?.weight_avg_fat ? (directChartData?.avgDirectFarmer?.weight_avg_fat) : ''}</div>
                          <div className="d-flex align-items-center smaller">
                            <div className={directChartData?.avgDirectFarmer?.tot_fat_symbol === "up" ? "change up" : "change down"}>
                              <Icon name={directChartData?.avgDirectFarmer?.tot_fat_symbol === "up" ? "upword-alt-fill" : "downward-alt-fill"}></Icon>
                              <span className="ms-1">{directChartData?.avgDirectFarmer?.tot_fat_percentage}%</span>
                            </div>
                            <span className="text-light">{days}</span>
                          </div>
                        </div>
                        <div className="nk-chart-analytics-session">
                          <ChartLine data={fat_avg_data} stepSize={0.10} />

                        </div>
                        <ChartLabel.Group className="justify-content-between mt-1">
                          {chartLabels}
                        </ChartLabel.Group>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col sm="6" xxl="3">
                    <Card className="h-100">
                      <Card.Body>
                        <div className="card-title-group align-items-start">
                          <div className="card-title">
                            <h4 className="title">Avg.Snf</h4>
                          </div>
                          <Media size="sm" shape="circle" variant="success-soft">
                            <Icon name="bar-chart-fill"></Icon>
                          </Media>
                        </div>
                        <div className="mt-2 mb-4">
                          <div className="amount h1">{directChartData?.avgDirectFarmer?.weight_avg_snf ? (directChartData?.avgDirectFarmer?.weight_avg_snf) : ''}</div>
                          <div className="d-flex align-items-center smaller">
                            <div className={directChartData?.avgDirectFarmer?.tot_snf_symbol === "up" ? "change up" : "change down"}>
                              <Icon name={directChartData?.avgDirectFarmer?.tot_snf_symbol === "up" ? "upword-alt-fill" : "downward-alt-fill"}></Icon>
                              <span className="ms-1">{directChartData?.avgDirectFarmer?.tot_snf_percentage}%</span>
                            </div>
                            <span className="text-light">{days}</span>
                          </div>
                        </div>
                        <div className="nk-chart-analytics-session">
                          <ChartLine data={snf_avg_data} stepSize={0.10} />
                        </div>
                        <ChartLabel.Group className="justify-content-between mt-1">
                          {chartLabels}
                        </ChartLabel.Group>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col sm="6" xxl="3">
                    <Card className="h-100">
                      <Card.Body>
                        <div className="card-title-group align-items-start">
                          <div className="card-title">
                            <h4 className="title">Avg.Lit</h4>
                          </div>
                          <Media size="sm" shape="circle" variant="warning-soft">
                            <Icon name="activity-round-fill"></Icon>
                          </Media>
                        </div>
                        <div className="mt-2 mb-4">
                          <div className="amount h1">{directChartData?.avgDirectFarmer?.avg_tot_qty_litre ? (directChartData?.avgDirectFarmer?.avg_tot_qty_litre) : ''}</div>
                          <div className="d-flex align-items-center smaller">
                            <div className={directChartData?.avgDirectFarmer?.avg_tot_qty_litre_symbol === "up" ? "change up" : "change down"}>
                              <Icon name={directChartData?.avgDirectFarmer?.avg_tot_qty_litre_symbol === "up" ? "upword-alt-fill" : "downward-alt-fill"}></Icon>
                              <span className="ms-1">{(directChartData?.avgDirectFarmer?.tot_qty_litre_percentage)}%</span>
                            </div>

                          </div>
                        </div>
                        <div className="nk-chart-analytics-session">
                          <ChartLine data={lit_avg_data} stepSize={0.10} />
                        </div>
                        <ChartLabel.Group className="justify-content-between mt-1">
                          {chartLabels}
                        </ChartLabel.Group>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col sm="6" xxl="3">
                    <Card className="h-100">
                      <Card.Body>
                        <div className="card-title-group align-items-start">
                          <div className="card-title">
                            <h4 className="title">Avg.Kg</h4>
                          </div>
                          <Media size="sm" shape="circle" variant="info-soft">
                            <Icon name="tag-fill"></Icon>
                          </Media>
                        </div>
                        <div className="mt-2 mb-4">
                          <div className="amount h1">{directChartData?.avgDirectFarmer?.avg_tot_qty_kg ? (directChartData?.avgDirectFarmer?.avg_tot_qty_kg) : ''}</div>
                          <div className="d-flex align-items-center smaller">
                            <div className={directChartData?.avgDirectFarmer?.tot_qty_kg_symbol === "up" ? "change up" : "change down"}>
                              <Icon name={directChartData?.avgDirectFarmer?.tot_qty_kg_symbol === "up" ? "upword-alt-fill" : "downward-alt-fill"}></Icon>
                              <span className="ms-1">{directChartData?.avgDirectFarmer?.tot_qty_kg_percentage}%</span>
                            </div>
                            <span className="text-light">{days}</span>
                          </div>
                        </div>
                        <div className="nk-chart-analytics-session">
                          <ChartLine data={qty_avg_data} stepSize={0.10} />
                        </div>
                        <ChartLabel.Group className="justify-content-between mt-1">
                          {chartLabels}
                        </ChartLabel.Group>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md="6">
                    <Card >
                      <Card.Body>
                        <div>
                          <Button
                            className='d-flex float-end'
                            as="label"
                            variant="primary"
                            onClick={() => setShowAFilter(!showAFilter)}
                          >
                            <Icon name='filter' />
                          </Button>
                        </div>
                        {showAFilter && <AttendenceFilter attendenceFilter={attendenceDate} />}

                        <Row className="g-gs">
                          <Col md="6">
                            <Card className="h-100">
                              <Card.Body>
                                <div className="d-flex flex-column ">
                                  <div className="card-title mb-0 mt-4 mt-sm-0">
                                    <h5 className="title mb-4">Present</h5>

                                    <div className='d-flex'>
                                      <Icon size="lg" variant="success" name="user-add"></Icon>
                                      <div className="amount h1">{attendence?.presentFarmer}</div>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>

                          <Col md="6">
                            <Card className="h-100">
                              <Card.Body>
                                <div className="d-flex flex-column ">
                                  <div className="card-title mb-0 mt-4 mt-sm-0">
                                    <h5 className="title mb-4 ">Absent</h5>
                                    <div className='d-flex'>
                                      <Icon size="lg" variant="danger" name="user-add"></Icon>
                                      <div className="amount h1">{attendence?.absentFarmer}</div>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md="3">
                    <Card className="h-100">
                      <Card.Body>
                        <div className="d-flex flex-column flex-sm-row-reverse align-items-sm-center justify-content-sm-between gx-xl-5">
                          <div className="nk-chart-project-earnings">
                            <ChartLine data={totalClientsChart} />
                          </div>
                          <div className="card-title mb-0 mt-4 mt-sm-0">
                            <h5 className="title mb-3 mb-xl-5">Total Litres</h5>
                            <div className="amount h1">{directChartData?.avgDirectFarmer?.tot_qty_litre}</div>
                            <div className="d-flex align-items-center smaller flex-wrap">
                              <div className={directChartData?.avgDirectFarmer?.avg_tot_qty_litre_symbol === "up" ? "change up" : "change down"}>
                                <Icon name={directChartData?.avgDirectFarmer?.tot_qty_kg_symbol === "up" ? "upword-alt-fill" : "downward-alt-fill"}></Icon>
                                {directChartData?.avgDirectFarmer?.tot_qty_litre_percentage}%
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md="3">
                    <Card className="h-100">
                      <Card.Body>
                        <div className="d-flex flex-column flex-sm-row-reverse align-items-sm-center justify-content-sm-between gx-xl-5">
                          <div className="nk-chart-project-earnings">
                            <ChartLine data={totalClientsChart} />
                          </div>
                          <div className="card-title mb-0 mt-4 mt-sm-0">
                            <h5 className="title mb-3 mb-xl-5">Total KG</h5>
                            <div className="amount h1">{directChartData?.avgDirectFarmer?.tot_qty_kg}</div>
                            <div className="d-flex align-items-center smaller flex-wrap">
                              <div className={directChartData?.avgDirectFarmer?.avg_tot_qty_litre_symbol === "up" ? "change up" : "change down"}>
                                <Icon name={directChartData?.avgDirectFarmer?.tot_qty_kg_symbol === "up" ? "upword-alt-fill" : "downward-alt-fill"}></Icon> {directChartData?.avgDirectFarmer?.tot_qty_kg_percentage}%
                              </div>

                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col xxl="6">
                    <Card className="h-100">
                      <Card.Body className="flex-grow-0 py-2">
                        <div className="card-title-group">
                          <div className="card-title">
                            <h5 className="title">Top Farmer List</h5>
                          </div>
                          <div className="card-title">
                            <SessionStartSelector analyzer home onChange={sessionHandler} value={sessionList} />
                          </div>
                          <div className="card-tools">
                            <Dropdown onSelect={handleDropdownChange}>
                              <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                                <Icon name="more-v"></Icon>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                                <Dropdown.Header className="py-2">
                                  <h6 className="mb-0">Options</h6>
                                </Dropdown.Header>
                                <Dropdown.Divider className="mt-0" />
                                <Dropdown.Item eventKey="5">5 members</Dropdown.Item>
                                <Dropdown.Item eventKey="10">10 members</Dropdown.Item>
                                <Dropdown.Item eventKey="15">15 members</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </Card.Body>
                      <Table responsive className="table-middle mb-0">
                        <DataTable className="data-table-head-light table-responsive" data={topFarmerData}
                          columns={topFarmerColumn} persistTableHead
                        />
                      </Table>
                    </Card>
                  </Col>

                  <Col xxl="6">
                    <Card className="h-100">
                      <Card.Body className="flex-grow-0 py-2">
                        <div className="card-title-group">
                          <div className="card-title">
                            <h5 className="title">Worst Farmer List</h5>
                          </div>
                          <div className="card-tools">
                            <Dropdown>
                              <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                                <Icon name="more-v"></Icon>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                                <Dropdown.Header className="py-2">
                                  <h6 className="mb-0">Options</h6>
                                </Dropdown.Header>
                                <Dropdown.Divider className="mt-0" />
                                <Dropdown.Item>5 members</Dropdown.Item>
                                <Dropdown.Item>10 members</Dropdown.Item>
                                <Dropdown.Item>15 members</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </Card.Body>
                      <Table responsive className="table-middle mb-0">
                        <DataTable className="data-table-head-light table-responsive" data={belowFarmerData}
                          columns={worstFarmerColumn} persistTableHead
                        />
                      </Table>
                    </Card>
                  </Col>

                </Row>
              </Card.Body>
            </Card>

          </Tab.Pane>


          <Tab.Pane eventKey="vlcc">
            <Card>
              <Card.Body>

                <Row className="g-gs">
                  <Col sm="6" xxl="3">
                    <Card className="h-100">
                      <Card.Body>
                        <div className="card-title-group align-items-start">
                          <div className="card-title">
                            <h4 className="title">Avg.Fat</h4>
                          </div>
                          <Media size="sm" shape="circle" variant="success-soft">
                            <Icon name="bar-chart-fill"></Icon>
                          </Media>
                        </div>
                        <div className="mt-2 mb-4">
                          <div className="amount h1">{vlccChartData?.avgVlccFarmer?.avg_tot_fat ? vlccChartData?.avgVlccFarmer?.avg_tot_fat : ''}</div>
                          <div className="d-flex align-items-center smaller">
                            <div className={vlccChartData?.vlcc_farmer?.tot_fat_symbol === "up" ? "change up" : "change down"}>
                              <Icon name={vlccChartData?.vlcc_farmer?.tot_fat_symbol === "up" ? "upword-alt-fill" : "downward-alt-fill"}></Icon>
                              <span className="ms-1">{vlccChartData?.vlcc_farmer?.tot_fat_percentage}%</span>
                            </div>
                            <span className="text-light">From today</span>
                          </div>
                        </div>
                        <div className="nk-chart-analytics-session">
                          <ChartLine data={fat_avg_data_vlcc} />
                        </div>
                        <ChartLabel.Group className="justify-content-between mt-1">
                          {chartLabelsVlcc}
                        </ChartLabel.Group>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col sm="6" xxl="3">
                    <Card className="h-100">
                      <Card.Body>
                        <div className="card-title-group align-items-start">
                          <div className="card-title">
                            <h4 className="title">Avg.Snf</h4>
                          </div>
                          <Media size="sm" shape="circle" variant="success-soft">
                            <Icon name="bar-chart-fill"></Icon>
                          </Media>
                        </div>
                        <div className="mt-2 mb-4">
                          <div className="amount h1">{vlccChartData?.avgVlccFarmer?.avg_tot_snf ? vlccChartData?.avgVlccFarmer?.avg_tot_snf : ''}</div>
                          <div className="d-flex align-items-center smaller">
                            <div className={vlccChartData?.vlcc_farmer?.tot_snf_symbol === "up" ? "change up" : "change down"}>
                              <Icon name={vlccChartData?.vlcc_farmer?.tot_snf_symbol === "up" ? "upword-alt-fill" : "downward-alt-fill"}></Icon>
                              <span className="ms-1">{vlccChartData?.vlcc_farmer?.tot_snf_percentage}%</span>
                            </div>
                            <span className="text-light">From today</span>
                          </div>
                        </div>
                        <div className="nk-chart-analytics-session">
                          <ChartLine data={snf_avg_data_vlcc} />
                        </div>
                        <ChartLabel.Group className="justify-content-between mt-1">
                          {chartLabelsVlcc}
                        </ChartLabel.Group>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col sm="6" xxl="3">
                    <Card className="h-100">
                      <Card.Body>
                        <div className="card-title-group align-items-start">
                          <div className="card-title">
                            <h4 className="title">Avg.Lit</h4>
                          </div>
                          <Media size="sm" shape="circle" variant="warning-soft">
                            <Icon name="activity-round-fill"></Icon>
                          </Media>
                        </div>
                        <div className="mt-2 mb-4">
                          <div className="amount h1">{vlccChartData?.avgVlccFarmer?.avg_tot_qty_litre ? vlccChartData?.avgVlccFarmer?.avg_tot_qty_litre : ''}</div>
                          <div className="d-flex align-items-center smaller">
                            <div className={vlccChartData?.vlcc_farmer?.avg_tot_qty_litre_symbol === "up" ? "change up" : "change down"}>
                              <Icon name={vlccChartData?.vlcc_farmer?.avg_tot_qty_litre_symbol === "up" ? "upword-alt-fill" : "downward-alt-fill"}></Icon>
                              <span className="ms-1">{(vlccChartData?.vlcc_farmer?.tot_qty_litre_percentage)}%</span>
                            </div>
                            <span className="text-light">From today</span>
                          </div>
                        </div>
                        <div className="nk-chart-analytics-session">
                          <ChartLine data={lit_avg_data_vlcc} />
                        </div>
                        <ChartLabel.Group className="justify-content-between mt-1">
                          {chartLabelsVlcc}
                        </ChartLabel.Group>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col sm="6" xxl="3">
                    <Card className="h-100">
                      <Card.Body>
                        <div className="card-title-group align-items-start">
                          <div className="card-title">
                            <h4 className="title">Avg.Kg</h4>
                          </div>
                          <Media size="sm" shape="circle" variant="info-soft">
                            <Icon name="tag-fill"></Icon>
                          </Media>
                        </div>
                        <div className="mt-2 mb-4">
                          <div className="amount h1">{vlccChartData?.avgVlccFarmer?.avg_tot_qty_kg ? (vlccChartData?.avgVlccFarmer?.avg_tot_qty_kg)?.toFixed(2) : ''}</div>
                          <div className="d-flex align-items-center smaller">
                            <div className={vlccChartData?.vlcc_farmer?.tot_qty_kg_symbol === "up" ? "change up" : "change down"}>
                              <Icon name={vlccChartData?.vlcc_farmer?.tot_qty_kg_symbol === "up" ? "upword-alt-fill" : "downward-alt-fill"}></Icon>
                              <span className="ms-1">{vlccChartData?.vlcc_farmer?.tot_qty_kg_percentage}%</span>
                            </div>
                            <span className="text-light">From today</span>
                          </div>
                        </div>
                        <div className="nk-chart-analytics-session">
                          <ChartLine data={qty_avg_data_vlcc} />
                        </div>
                        <ChartLabel.Group className="justify-content-between mt-1">
                          {chartLabelsVlcc}
                        </ChartLabel.Group>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col xxl="6">
                    <Card className="h-100">
                      <Card.Body>
                        <div className="card-title-group flex-wrap">
                          <div className="card-title">
                            <h5 className="title">Audience Overview</h5>
                          </div>
                          <ChartLegend.Group className="gap gx-3 align-items-center">
                            <div className="gap-col">
                              <ChartLegend className="chart-legend-small" symbol="warning">
                                New visits
                              </ChartLegend>
                            </div>
                            <div className="gap-col">
                              <ChartLegend className="chart-legend-small" symbol="info">
                                Unique visits
                              </ChartLegend>
                            </div>
                            <div className="gap-col">
                              <Dropdown>
                                <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                                  <Icon name="more-v"></Icon>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                                  <Dropdown.Header className="py-2">
                                    <h6 className="mb-0">Options</h6>
                                  </Dropdown.Header>
                                  <Dropdown.Divider className="mt-0" />
                                  <Dropdown.Item>7 Days</Dropdown.Item>
                                  <Dropdown.Item>15 Days</Dropdown.Item>
                                  <Dropdown.Item>30 Days</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </ChartLegend.Group>
                        </div>
                        <div className="nk-chart-analytics-audience-overview mt-3">
                          <ChartLine data={attendenceVLCC} />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md="6">
                    <div className="card h-100">
                      <div className="card-body">
                        <div className="d-flex flex-column flex-sm-row-reverse align-items-sm-center justify-content-sm-between gx-xl-5">
                          <Pureknob data={totalAttendenceAbsent} className="nk-chart-project-done">
                            <span className="knob-title small text-light">Task Done</span>
                          </Pureknob>
                          <div className="card-title mb-0 mt-4 mt-sm-0">
                            <h5 className="title mb-3 mb-xl-5">Total Task Done</h5>
                            <div className="amount h1">768</div>
                            <div className="d-flex align-items-center smaller flex-wrap">
                              <div className="change up">
                                <Icon name="upword-alt-fill"></Icon> 14.2%
                              </div>
                              <span className="text-light">From last week</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                </Row>


              </Card.Body>
            </Card>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

    </Layout>
  )
}

export default Home;
